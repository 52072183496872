<template>
  <div class="race-details">
    <header class="sub">
      <back-button @click="cancel">Cancel</back-button>
      <h1 class="flexible-space">{{raceEvent.name}} {{race.name}}</h1>
      <button v-if="race.id && selectedTab == 'options'" class="danger" @click="doDeleteRace">Delete Race</button>
      <button class="cancel" @click="doSaveRace">Save</button>
    </header>
    
    <ul class="tabs">
      <li @click.prevent="selectedTab='options'" :class="{selected:selectedTab=='options'}">Options</li>
      <li @click.prevent="selectedTab='map'" :class="{selected:selectedTab=='map'}">Map</li>
      <li @click.prevent="selectedTab='splits'" :class="{selected:selectedTab=='splits'}">Splits</li>
      <li @click.prevent="selectedTab='waves'" :class="{selected:selectedTab=='waves'}">Waves</li>
      <li @click.prevent="selectedTab='shirts'" :class="{selected:selectedTab=='shirts'}">Shirts</li>
    </ul>

    <form class="content" onsubmit="return false">
      <fieldset class="options" v-show="selectedTab=='options'">
        <h2>Options</h2>
      
        <div class="form-row">
          <label for="name">Name</label>
          <input type="text" name="name" v-model="race.name" />

          <label for="name">Distance</label>
          <input type="number" name="name" v-model="raceDistance" step="0.001" class="mini" />
          <span class="unit">km</span>

        </div>

        <div class="form-row">
          <label for="max-pace">Fastest Pace</label>
          <div class="input-flex">
            <span class="input-flex-sizer"><input type="number" id="max-pace-min" v-model="maxPaceMinutes" placeholder="00"/></span>
            <span class="separator">:</span>
            <span class="input-flex-sizer"><input type="number" id="max-pace-sec" v-model="maxPaceSeconds" placeholder="00"/></span>
            <span class="unit">min/km</span>
          </div>
        </div>
        <div class="form-row">
          <label for="max-pace">&nbsp;</label>
          <div class="note" v-if="this.race && this.race.maxPace">
            <span class="ms">{{ this.race.maxPace.toFixed(2) }} m/s</span> | 
            <span class="mph"> {{ (this.race.maxPace * 2.236936).toFixed(2) }} mph</span>
          </div>
        </div>
        
      
        <div class="form-row">
          <label for="is_loop">Looped?</label>
          <input id="is_loop" type="checkbox" v-model="race.isLoop" />
          <label for="score_by_teams">Has Teams?</label>
          <input id="score_by_teams" type="checkbox" v-model="race.scoreByTeam" />
        </div>

        <div class="form-row-section prefixes">
          <div class="form-row prefixes">
            <label for="prefixes">Prefixes</label>
            <ul class="box naked">
              <li v-for="(prefix, i) in race.prefixes">
                <autocomplete-row
                  v-model="race.prefixes[i]"
                  :choices="commonPrefixes"
                  row-class="prefix-row"
                >
                  <button class="mini bar del" @click="removePrefix(i)">Remove</button>
                </autocomplete-row>
              </li>
            </ul>
          </div>
          <div class="control">
            <button class="mini add" @click="addPrefix">Add</button>
          </div>
        </div>

        <div class="form-row">
          <label for="is_timed">Fixed</label>
          <select name="is_timed" v-model="race.isTimed" class="squish">
            <option :value="false" default>Distance</option>
            <option :value="true">Time</option>
          </select>
        </div>

        <div class="form-row">
          <label for="gun_time">Initial Starts</label>
          <select name="gun_time" v-model="race.gunTime" class="squish">
            <option :value="true" default>Gun Time</option>
            <option :value="false">Chip Time</option>
          </select>
        </div>
        
        <div class="form-row">
          <label for="start_time">Start Time</label>
          <datetime-picker v-model="race.startTime" />
          <span class="note">{{ ddf.format(race.startTime) }} - {{ ddf.format(race.cutoff) }}</span>
        </div>

        <div class="form-row">
          <label for="start_time">Cutoff Time</label>
          <datetime-picker v-model="race.cutoff" />
          <span class="note">{{ $durationString((race.cutoff || (race.startTime + 86400000)) - race.startTime) }}</span>
        </div>

        <div class="form-row">
          <label for="lps_duration">LPS Round</label>
          <input type="number" id="lps_duration" v-model="race.lpsDuration" />
          <span class="unit">min</span>
        </div>

        <div class="form-row">
          <label for="color">Color</label>
          <input type="color" v-model="race.color">
        </div>
      </fieldset>
      
      <fieldset class="gpx" v-show="selectedTab=='map'">
        <div class="form-row gpx-row">
          <label for="race_track">GPX</label>
          <input id="race_track" type="file" @change="uploadTrack" />
          <loading-spinner v-if="gpxUploading" />
          <span class="note" @click="gpxUploading = !gpxUploading">{{ gpxMessage }}</span>
        </div>
        
        <race-details-map :race="race" ref="map" />
      </fieldset>
      
            
      <fieldset class="splits varlist-wrapper active-elms" v-show="selectedTab=='splits'">
        <header>
          <h2>Splits</h2>
        </header>
        <ul class="varlist">
          <li id="roaming-split" @click="selectSplit(-1, $event)" :class="{selected:-1==selectedSplit}">
            <span class="split-name">Roaming</span>
          </li>
          <li v-for="split in splits" @click="selectSplit(split, $event)" :class="{selected:split==selectedSplit}">
            <span class="split-name">{{split && split.name || '---'}}</span>
          </li>
        </ul>
        <footer class="control">
          <button class="add bar" @click="addSplit" title="Add Split">Add</button>
          <button class="del bar" @click="removeSelectedSplit" :disabled="!selectedSplit" title="Remove Split">Delete</button>
          <button class="bar"     @click="toggleCrewAccess" title="Toggle Crew Access for all Splits">Toggle Crew</button>
        </footer>
      </fieldset>

      <fieldset class="split-options" v-show="selectedTab=='splits'">
        <h2 v-if="-1 !== selectedSplit">Split Options</h2>
        <split-options ref="splitOptions" 
          :target-split="selectedSplit"
          :roaming-event-routes="roamingEventRoutes"
          @addRoamingEventRoute="addRoamingEventRoute"
          @removeRoamingEventRoute="removeRoamingEventRoute"
          @eventRouteChanged="eventRouteChanged"
          @requestApplyEventRoutesToSimilar="requestApplyEventRoutesToSimilar"
        >
        </split-options>
      </fieldset>
      
      <fieldset class="waves varlist-wrapper active-elms " v-show="selectedTab=='waves'">
        <header>
          <h2>Waves</h2>
        </header>

        <ul class="flex-scroller varlist">
          <li v-for="wave in waves" @click="selectWave(wave, $event)" :class="{selected:wave==selectedWave}">
            <span class="split-name">{{wave.id}} - {{ wave.time ? $root.shortTimestampFmt.format(new Date(wave.time)) : '-' }}</span>
          </li>
        </ul>

        <footer class="control">
          <button class="add bar" @click="addWave" title="Add Split">Add</button>
          <button class="del bar" @click="removeSelectedWave" :disabled="!selectedWave" title="Remove Wave">Delete</button>
          <span class="flexible-space"></span>
          <button class="bar" @click="reorderWaveIDs" title="Change split IDs to be ordered by wave time. Updates all participants' wave IDs.">Reorder</button>
        </footer>
      </fieldset>
      
      <fieldset class="wave-options" v-show="selectedTab=='waves'">
        <h2>Wave Options</h2>
        
        <div class="inset-box">
          <div class="form-row">
            <label>Time</label>
            <datetime-picker v-model="selectedWaveTime" />
          </div>
        </div>
      </fieldset>
      
      <fieldset class="shirts" v-if="selectedTab=='shirts'">
        <h2>Race-specific Shirts</h2>
        <div class="form-row">
          <label>Use counts from:</label>
          <select name="count_selection" v-model="race.shirtCounts.strategy">
            <option :value="undefined" default>Overall Event</option>
            <option value="this">This race</option>
            <option value="all">All participants get a shirt</option>
            <option v-for="r in otherRaces" :value="r.id">Group with {{r.name}}</option>
          </select>
        
          <div class="form-row">
            <label>Unisex</label>
            <toggle-switch v-model="race.shirtCounts.unisex" />
          </div>

          <div class="form-row">
            <label>Size Down Women</label>
            <toggle-switch v-model="race.shirtCounts.wsizedown" :disabled="!race.shirtCounts?.unisex"/>
          </div>
        
        </div>
      
        <template v-if="race.shirtCounts.unisex">
          <h4>Unisex</h4>
          <div class="form-row">
            <label>XS</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['xs']" />
            <label>S</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['s']" />
            <label>M</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['m']" />
            <label>L</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['l']" />
            <label>XL</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['xl']" />
            <label>2XL</label><input type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['xxl']" />
          </div>
        </template>
        <template v-else>
          <h4>Women's</h4>
          <div class="form-row">
            <label>XS</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['wxs']" />
            <label>S</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['ws']" />
            <label>M</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['wm']" />
            <label>L</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['wl']" />
            <label>XL</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['wxl']" />
            <label>2XL</label><input type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['wxxl']" />
          </div>
          <h4>Men's</h4>
          <div class="form-row">
            <label>XS</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['mxs']" />
            <label>S</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['ms']" />
            <label>M</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['mm']" />
            <label>L</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['ml']" />
            <label>XL</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['mxl']" />
            <label>2XL</label><input type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['mxxl']" />
          </div>
        </template>
        <h4>Youth</h4>
        <div class="form-row">
          <label>XS</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['yxs']" />
          <label>S</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['ys']" />
          <label>M</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['ym']" />
          <label>L</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['yl']" />
          <label>XL</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['yxl']" />
        </div>
      </fieldset>
    </form>
  </div>
</template>

<style lang="scss" scoped>
  .race-details {
    /* Full Width Dialog */
    min-width: 70vw;
    max-width: 100%;
    min-height: 500px;
    
    .unit { margin-left: 4px;}
    
    .tabs { margin-bottom: 1em;}
    
    form {
      display: flex;
      justify-content: stretch;
      margin-top: 4px;
      min-height: 500px;
      
      
      .prefixes {
        li {
          padding: 0 0.25em 0.25em 0;
        }
        
        .prefix-row {
          display: flex;
          align-items: baseline;
        }
        
        .control {
          margin-top: -0.5em;
          margin-bottom: 0.75em;
        }
      }
      
      .options {
        label {
          width: 100px;
          flex-grow: 0;
        }
      }
      
      input.flex {
        width: auto;
      }
    }

    fieldset {
//      background: purple;
      border: none;
      flex: 1;
      
      h2 { 
        margin: 0;
      }          
    }
    
    .options {
      flex-basis: 100px;
    }
    
    
    .splits {
      /* flex-basis: 240px;*/
      max-width: 400px;
      border: 1px solid rgba(0,0,0,0.2);
      border-right: none;
      
      #roaming-split {
        border-bottom: 1px solid var(--button-border-color);
        padding-bottom: 0.75em;
        margin-bottom: 0.75em;
      }
    }

    fieldset.split-options {
      flex-basis: 240px; // width
      display: flex;
      flex-direction: column;
      
      .split-options {
        flex-grow: 1;
      }
      .note { width: 80%;}
    }
    
  }
  
  .gpx {
    position: relative;

    .race-details-map {

      position: absolute;
      left: 0;
      right: 0;
      top: calc(64px + 1em);
      bottom: 0px;
      border-radius: var(--br);
    }
        
    .gpx-row {
      .spinner {
        --spinner-size: 22px;
      }

      // Don't move the note when the spinner spins.
      .note { margin-left: 22px; }
      .spinner+.note { margin-left: 0; }
    }
  }  
  
  @media screen and (max-width: 1300px) {
    form {
      .distance input { width: 60px !important; };
      th.cutoff { width: 50px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
    }
  }
  
  @media screen and (max-width: 1000px) {
    form {
      display: block !important;
      width: auto !important;
      
      .splits {
        table {
          width: inherit !important;
        }
      }
      
    }
  }
</style>

<script>
  import SplitOptions from "@/views/split-options.vue"
  import schema from '@/entity.js'
  import {denormalize} from 'normalizr'
  import Color from 'color';
  import GeoJSON from '@mapbox/togeojson'
  import haversine from 'haversine-distance'
  import {processLinestring, processWaypoints, mergeSplitsWithGeoJSON, coordsForDistance} from '@/lib/gpx-tools.js'
  import RaceDetailsMap from "@/views/race-details-map.vue"
  
  export default {
    
    components: {
      SplitOptions,
      RaceDetailsMap
    },
    
    props: {
      race: {
        type: Object,
        default() {
          return {
            id: this.$store.getters.randomID(),
            splits: [],
          }
        }
      }
    },
    
    data() {
      return {
        selectedTab: 'options',
        // Support the cancel button. We modify a race in real-time,
        // but the timing person might want to get rid of changes.
        //
        originalAttributes: {},
        // attributes: {
        //   splits: [],
        //   name: '',
        //   distance: 0,
        //   isLoop: false,
        //   isTimed: false,
        //   gunTime: false,
        //   startTime: undefined,
        //   cutoff: undefined
        // },

        startTime: undefined,

        newSplits: [], // objects that have not yet been persisted to the database
        roamingEventRouteChanges: [], // {type: 'add', id: 43 }

        selectedSplit: null,
        selectedWave: null,

        gpxUploading: false,
        gpxMessage: '',
      }
    },
        
    computed: {
      ddf() {
        let timeZone;
        switch (this.raceEvent.timezoneOffset) {
          case -7: timeZone = 'America/Phoenix'; break;
          default: timeZone = `Etc/GMT+${this.raceEvent.timezoneOffset * -1}`; break;
        }
        
        return new Intl.DateTimeFormat('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric', 
          minute: '2-digit', 
          second: '2-digit',
          timeZone,
          hour12: true,
          timeZoneName: 'short'
        })
      },
      
      eventSources() {
        return this.$store.state.eventSources;
      },
      
      roamingEventRoutes() {
        // Integrate all the roaming event source changes into 
        // the actual roaming event sources, adding & removing
        //
        const raceId = this.race?.id;
        if (!raceId) return [];
        
        let res = Object.values(this.$store.state.eventRoutes || {})
          .filter(er => er.raceId==raceId && er.splitId === null);
          
        if (res === undefined) return [];
        
        this.roamingEventRouteChanges.forEach(change => {
          console.debug('Applying roaming event source change', change);
          if (change.type === 'add')
          {
            res.push(change);
          }
          else
          if (change.type === 'remove')
          {
            // find the entry in res
            let i;
            for (i = res.length - 1; i >= 0; i--) {
              if (res[i].id === change.id)
                 break;
            }
            if (i < 0) {
              console.error("Could not find change to apply", change);
            }
            else {
              res.splice(i, 1);
            }
          }
        })
        
        return res;
      },
      
      raceEvent() {
        return this.$store.getters.entity('raceEvents', this.race.raceEventId);
      },
      
      otherRaces() {
        if (!this.raceEvent)
          return [];
        
        return this.$store.getters.entity('races', this.raceEvent.races).filter(r=> r != this.race)
      },
      
      splits() {
        console.log(`sorting splits for race id ${this.race.id}`, this.race.splits);

        let currentSplits = 
          this.$store.getters.entity('splits', this.race.splits)
          .filter(split=> split.id)
          .sort((a,b) => (a.distance || 0) - (b.distance || 0));

        return currentSplits;
      },
      
      commonPrefixes() {
        return ['ac2349071000', 'ac2300000999'];
      },
      
      shirtCountDisabled() {
        return this.useRaceEventShirtCounts;
      },
      
      useRaceEventShirtCounts: {
        get() {
          return !this.race.shirtCounts || this.race.shirtCounts.disabled;
        },

        set(v) {
          if (!this.race.shirtCounts)
            this.race.shirtCounts = {}

          this.race.shirtCounts.disabled = true;
        }
      },
      
      waves() {
        if (!this.race || !this.race.waves)
          return [];
        
        return this.race.waves.waves;
      },
      
      raceDistance: {
        get()  { return this.race.distance / 1000.0; },
        set(v) { this.race.distance = v * 1000}
      },
      
      maxPaceMinPerKm() {
        let s_km = 1 / (this.race.maxPace  / 1000.0);
        let min = Math.floor(s_km / 60);
        let sec = Math.round(s_km - min * 60);
        return  { min, sec };
      },
      
      maxPaceMinutes: {
        get() { return this.maxPaceMinPerKm.min },
        set(v) { this.setRacePace(v, this.maxPaceMinPerKm.sec); }
      },
      
      maxPaceSeconds: {
        get() { 
          let v = this.maxPaceMinPerKm.sec; 
          if (!v) return '00';
          return ("0" + v.toString()).slice(-2)
        },
        set(v) { this.setRacePace(this.maxPaceMinPerKm.min, v); }
      },
            
      // The datetime-picker component for wave start times needs
      // some help getting the correct value where it needs to go
      selectedWaveTime: {
        get() { return this.selectedWave ? this.selectedWave.time : undefined },
        set(v) {
          if (this.selectedWave) {
            this.selectedWave.time = v;
          }
        }
      },
      
      roamingEventSourceIds() {
        if (!this.race) return [];
        if (!this.race.roamingESIDs) {
          this.$set(this.race, 'roamingESIDs', []);
        }
        
        return this.race.roamingESIDs;
      }
    },
    
    created() {
      // Create race attributes working copy
      // this.attributes = Object.assign(this.attributes, this.race);

      // Copy the race object to restore it on cancel.
      this.originalAttributes = Object.assign({}, this.race);
    },

    activated() {
      // Hide cancel/save buttons after we get updated with the $modalWindow we (are probably) in.
      // Race Details are saved upon clicking "Back". The timing worker can still cancel
      // at the RaceEventDetails level.
      //
      console.warn("race-details activated"); // Is this code dead?
      this.$nextTick(() => {
        this.$modalWindow && 
          this.$modalWindow.setControlButtonsShown(false) ||
            console.error("Expected to be in $modalWindow");
      });
    },
    
    watch: {
      race(newRace) {
        if (newRace.linestring) {
          this.gpxMessage = 'Set.';
        }
      },
      
      'race.isTimed': function(isFixedTime, oldVal) {
        if (isFixedTime) {
          // Remove zero-length splits, if any
          const s = this.splits.find(s => s.distance===0);          
          const path = `/race_events/${this.$store.state.activeRaceEvent.id}/races/${this.race.id}/splits/${s.id}`;
        
          this.$store.dispatch('destroyEntity', {
            entity: s, 
            entitySchema: schema.split,
            path
          }).then(oldSplit => {
            // split is now deleted and this is the only reference.
          });
        }
        else 
        {
          // Inject a start split if there isn't one.
          const startSplit = this.splits.find(s => s.distance === 0);
          if (!startSplit) {
            this.injectStartSplit();
          }
        }
      },
      
      'race.shirtCounts.unisex': function(isUnisex) {
        if (!isUnisex)
          this.race.shirtCounts.wsizedown = false;
      },
      
      selectedTab(newVal) {
        if (newVal == 'map') {
          this.$refs.map.didDisplay();
        }
      },
    },
    
    mounted() {
      this.$store.dispatch('loadEntities', {
        url: `race_events/${this.raceEvent.id}/races/${this.race.id}/linestring`, 
        entity: schema.race
      }).then(r => {
        if (!this.race.prefixes)
          this.$set(this.race, 'prefixes', []);
      });
    },
    
    methods: {
      
      willShowInNavView() {
        this.$nextTick(() => {
          this.$modalWindow.setControlButtonsShown(false)
          
          console.debug("race-details willShowInNavView");
      
          const startSplit = this.splits.find(s => s.distance === 0);
          const finishSplit = this.splits.find(s => this.race.distance - s.distance < (this.race.distance * 0.1));
          
          console.debug("start split:", startSplit);
          console.debug("finish split:", finishSplit);
          
          // inject a zero-distance start split if there isn't one.
          if (!startSplit && !this.race.isTimed) {
            this.injectStartSplit();
          }

          // inject a finish-distance finish split if there isn't one.
          // If the distance changes, the "finish split" will no longer
          // satisfy this check, but it would be inappropriate to inject
          // another one, hence the count check. 
          if (!finishSplit && this.splits.length < (this.race.isTimed ? 1 : 2)) {
            this.injectFinishSplit();
          }
        });
      },
      
      // inject a zero-distance start split if there isn't one.
      injectStartSplit() {
        let newId = this.$store.getters.randomID();
        let payload = {splits: {}};
        console.info("Injecting start split with ID", newId);
        payload.splits[newId] = {
          id: newId, 
          name: 'Start', 
          raceId: this.race.id, 
          distance: 0, 
          cutoff: undefined 
        };

        this.race.splits.push(newId);

        // Finish injecting the things with NEW- ids.
        this.$store.commit('commitEntities', payload);
      },
      
      // inject a finish-distance finish split if there isn't one.
      injectFinishSplit() {
        console.info("Injecting finish split");
        let newId = this.$store.getters.randomID()
        let payload = {splits: {}};
        payload.splits[newId] = {
          id: newId,
          name: 'Finish', 
          raceId: this.race.id,
          distance: this.race.distance, 
          cutoff: undefined 
        };
        this.race.splits.push(newId);

        // Finish injecting the things with NEW- ids.
        this.$store.commit('commitEntities', payload);
      },
      
      addPrefix() {
        console.debug("Adding prefix");
        this.race.prefixes.push('000000000000');
      },
      
      removePrefix(i) {
        console.debug(`Removing prefix ${i}`);
        this.race.prefixes.splice(i,1);
      },
      
      // this is partially duplicated from split-options and should be put somewhere more sane.
      // committing the eventRoute is left to the caller so it can be done in bulk
      //
      addEventRoute(split, eventSourceId) {
        
        if (!split.eventRoutes)
          this.$set(split, 'eventRoutes', []);
      
        const newId = this.$store.getters.randomID();
        let route = { id: newId, splitId: split.id, eventSourceId: eventSourceId };
      
        // Insert into store. This will create a normalized entry under
        // e.g. the key NEW-13451346. If we don't save, we need to delete
        // these on cleanup.
        return route;
      },
      
      
      requestApplyEventRoutesToSimilar(modelSplit) {
        console.log("race-details: apply to similar", modelSplit);
        
        let payload = {eventRoutes: {}};
        
        this.splits.forEach(s => {
          if (s == modelSplit) return;
          
          let modelGeom = modelSplit.point?.geometry?.coordinates || [];
          let candidateGeom = s.point?.geometry?.coordinates || [];
          
          let deltaDist = haversine(modelGeom, candidateGeom);
          if (deltaDist < 100) {
            console.log(`Candidate point ${s.name} is ${deltaDist} away from model point`);
            
            let ers = modelSplit.eventRoutes || [];
            ers.forEach(erid => {
              let erToCopy = this.$store.state.eventRoutes[erid];
              console.debug("-> Copying ER", erid, erToCopy);
              let newEr = this.addEventRoute(s, erToCopy.eventSourceId);
              payload.eventRoutes[newEr.id] = newEr;
            })
          }
        });
        
        console.log("made new event routes:", payload);
        
        // Commit what we just made
        this.$store.commit('commitEntities', payload);
      },
      
      toggleCrewAccess() {
        let flag = !this.splits.every(s => s.crewAccessible)
        this.splits.forEach(s => {
          s.crewAccessible = flag
        })
      },
      
      addRoamingEventRoute() {
        // console.error("implement me in race-details");
        this.roamingEventRouteChanges.push({
          type: 'add',
          id: this.$store.getters.randomID(),
        });
      },
      
      removeRoamingEventRoute(eventRoute) {
        // If we have an ephemeral add that hasn't been saved, remove it.
        // this.roamingEventRouteChanges.find(c => c.type=='add' && id)
        let change = this.roamingEventRouteChanges.find(ch => ch.id == eventRoute.id && ch.type=='add');
        if (change) {
          // This is ephemeral.
          let i = this.roamingEventRouteChanges.indexOf(change);
          this.roamingEventRouteChanges.splice(i, 1);
          return;
        }
        
        // This is a persisted eventRoute
        this.roamingEventRouteChanges.push({
          type: 'remove',
          id: eventRoute.id
        });
      },
      
      eventRouteChanged(eventRoute, changedAttributes) {
        // We don't care about non-roaming event routes here.
        if (eventRoute.splitId !== undefined)
          return;
        
        // Find an existing change entry.
        // Ephemeral change entries will have attributes bound to controls and will
        // update themselves directly.
        //
        let change = this.roamingEventRouteChanges.find(ch => ch.id == eventRoute.id);
        if (change && change.type == 'add') {
          // Ephemeral; let it update directly.
        }
        else {
          // Either there is no change, or the change is not an ephemeral addition.
          // We're going to ignore the case where type=='remove' changes come through
          // here.
          if (!change) {
            change = {
              id: eventRoute.id,
              type: 'change',
              changes:{}
            };
            this.roamingEventRouteChanges.push(change);
          }          
          
          // If this was a persisted event route that changed. We need to make a note 
          // to send the updated eventSourceId or whatever.
          //
          // We'll treat it like that now.
          //
          Object.keys(changedAttributes).forEach(k => change.changes[k] = eventRoute[k]);
        }
      },
      
      cancel(e) {
        // Don't save
        this.$navigationView.pop();
      },
      
      doDeleteRace(e) {
        if (confirm(`Really delete ${this.race.name}? This cannot be undone.`))
        {
          // this.$store.dispatch('destroyRace', this.race).then(() => this.cancel());
          this.$store.dispatch('destroyEntity', {
            entity: this.race,
            entitySchema: schema.race,
            path: `race_events/${this.race.raceEventId}/races/${this.race.id}`
          }).then(r => {
            this.$navigationView.pop();
          });
        }
      },
      
      doSaveRace(e) {
        // Save all data.
        // Denormalize for the update. This is awkward because attribtues might
        // represent an unpersisted (new) object not in the db or store. Its 
        // splits might also be a mixture of persisted and unpersisted.
        //
        
        // Denormalize splits and eventRoutes
        // This creates copies, not references.
        let payload = denormalize(this.race.id, schema.race, this.$store.state);
        
        // Add roaming event routes
        payload.roamingEventRoutes = this.roamingEventRouteChanges;
                  
        // Since this is a copy, we can safely remove participants id array
        delete payload.participants;
        
        // Append new eventRoutes
        // This is probably not needed.
        this.$refs.splitOptions.willSave(payload.splits);

        this.$store.dispatch('saveEntity', {
          path: `race_events/${this.race.raceEventId}/races`,
          entity: schema.race,
          object: payload,
        })
        .then((result) => {
          console.debug("Saved race successfully", result);
          this.$navigationView.pop();
        })
        .catch((err) => {
          console.error("Couldn't save race; errors:", err);
        })

      },
      
      selectSplit(split, event) {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
        
        this.selectedSplit = split;
      },
      
      addSplit() {
        const split = {
          id: this.$store.getters.randomID(),
          raceId: this.race.id,
          name: 'New Split'
        };
        
        let payload = {splits: {}};
        payload.splits[split.id] = split;
        this.$store.commit('commitEntities', payload);
        
        this.selectSplit(split);
        this.$refs.splitOptions.focusSplitName(true);

        return split;
      },
      
      removeSplit(split) {
        console.log("removeSplit1", split);
        
        // Only bother confirming if the split actually has non-default attributes
        let needsConfirmation = (!!split.name && split.distance > 0);
        if (needsConfirmation)
          needsConfirmation = !confirm(`Really delete split '${split.name || "Unnamed Split"}'? This cannot be undone.`);
        
        console.log("Needs Confirmation now", needsConfirmation);
        
        if (needsConfirmation) 
        {
          console.warn("Could not get confirmation; not deleting");
        }
        else
        {
          console.log("removeSplit 2", split);
          // this.race.id might be ephemeral too. In practice, it should never happen
          // that we have a new race with a persisted split that we need to delete.
          this.$store.dispatch('destroyEntity', {
            entity: split,
            entitySchema: schema.split,
            path: `race_events/${this.race.raceEventId}/races/${this.race.id}/splits/${split.id}`
          }).then(r => {
            this.selectSplit(null);
          });
        }

      },
      
      removeSelectedSplit(){
        if (!this.selectSplit)
          return;
        
        this.removeSplit(this.selectedSplit);
      },
      
      setRacePace(min, sec) {
        min = parseInt(min); 
        if (isNaN(min)) min = 0;
        sec = parseInt(sec); 
        if (isNaN(sec)) sec = 0;
        
        let s = min * 60 + sec;
        console.log(`set pace ${min}:${sec} -> sec ${s}`);
        let m_s = 1 / s * 1000;

        this.race.maxPace = m_s;
      },
      
      
      selectWave(wave, event) {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
        
        this.selectedWave = wave;
      },
      
      addWave() {
        if (!this.race.waves)
          this.race.waves = {attrs:{},waves:[]};
        
        
        const waveSpacingMin = this.race.waves.attrs && this.race.waves.attrs.spacingMin || 5; 
        const lastWave = this.waves && this.waves[this.waves.length-1];
        let time ;
        if (lastWave && lastWave.time)
          time = new Date(new Date(lastWave.time).getTime() + waveSpacingMin*60000);
        else
          time = this.race.startTime;
        
        const wave = {
          id: this.waves ? this.waves.length+1 : 1,
          name: 'New Wave',
          time: time
        };
        
        if (!this.race.waves.waves) {
          this.race.waves.waves = [];
          this.race.waves.attrs = {};
        }
        
        this.race.waves.waves.push(wave);
        this.selectWave(wave);
        // this.$refs.splitOptions.focusSplitName(true);

        return wave;
      },
      
      removeWave(wave) {
        
        // Only bother confirming if the split actually has non-default attributes
        let needsConfirmation = true;
        if (needsConfirmation)
          needsConfirmation = !confirm(`Really delete wave? This cannot be undone.`);
        
        if (needsConfirmation) 
        {
          console.warn("Could not get confirmation; not deleting");
        }
        else
        {
          let i = this.waves.indexOf(wave);
          if (i >= 0) {
            this.waves.splice(i, 1);
          }
        }

      },
      
      removeSelectedWave(){
        if (!this.selectWave)
          return;
        
        this.removeWave(this.selectedWave);
      },
      
      reorderWaveIDs() {
        this.$cable.perform({channel: 'ControlChannel', action: 'reorder_wave_ids', data: {
          raceId: this.race.id
        }});
      },
      
      
      /* GPX */
      uploadTrack(e) {
        let file;
        const reader = new FileReader();
        reader.onprogress = function(x) { console.log("upload track prog: ", x) };
        reader.onabort = (e) => { 
          console.error("Abort reading file: ", reader.error); 
          this.gpxMessage = 'Failed'
          this.gpxUploading = false;
        }
        
        // Some sort of text here hopefully.
        reader.onloadend = function(x) { 
          console.log(`reader.onloadend ${!reader.result ? "NULL" : "Success"} for ${file.type}`); 
          this.gpxMessage = "Parsing...";
          
          const type = file.type || file.name.split('.').pop(); // fallback to extension
          
          console.log(`Got file of type: '${type}'`);
          
          switch (type) {
            case 'json':
            case 'geojson':
            case 'application/json': 
              this.gpxMessage = "Processing GeoJSON...";
              this.handleNewGeoJSON(JSON.parse(reader.result)); 
              break;
              
            case 'gpx':
            case 'application/xml':
            case 'text/xml':
            case 'application/gpx+xml':
            {
              this.gpxMessage = "Processing GPX...";
              const parser = new DOMParser();
              const doc = parser.parseFromString(reader.result, "text/xml");
              const gj = GeoJSON.gpx(doc);
              this.handleNewGeoJSON(gj);
              break;
            }
            
            case 'kml':
            case 'application/vnd.google-earth.kml+xml':
            {
              this.gpxMessage = "Processing KML...";
              const parser = new DOMParser();
              const doc = parser.parseFromString(reader.result, "text/xml");
              const gj = GeoJSON.kml(doc);
              this.handleNewGeoJSON(gj);
              break; 
            }

          default:
            alert(`Unknown file type ${file.type || "(unknown)"}`);
            this.gpxMessage = 'Failed to parse';
            this.gpxUploading = false;
            break;
          }
        }.bind(this);

        if (e.target.files.length > 0) {
          this.gpxUploading = true;
          file = e.target.files[0];
          console.debug(`Uploading type ${file.type || '(unset)'}`, file);
          reader.readAsText(file);
        }
      },
      
      async handleNewGeoJSON(gj) {
        // Find the linestring
        let linestringFeature = gj.features.find(f => f.geometry?.type === 'LineString')

        if (!linestringFeature) {
          alert ("No GPX linestring found in file")
          debugger
          return;
        }
        
        // We need to set the GeoJSON in order to be able to query
        // elevation, so let's do that as soon as possible.
        this.setGeoJSON(gj, true);
        
        // This is a super hacky way of loading the elevation data we need
        const map = this.$refs.map.map;
        gj.features.forEach( async  f=>{
          map.setZoom(14);
          if (f.geometry?.type == 'Point' && f.geometry.coordinates && !f.geometry.coordinates[2]) {
            console.log("center to point:", f.geometry.coordinates)
            try {
              let c = f.geometry.coordinates.slice(2);
              map.setCenter(c);
              await this.$refs.map.map.once('idle');
            }
            catch (e) {}
          }
        });
        
        await this.$refs.map.map.once('idle');

        // Check for elevation data
        if (linestringFeature.geometry.coordinates.find(c=>!c[2]))
        {
          this.gpxMessage = 'Adding elevation data';
          this.deriveElevationData(linestringFeature);
          this.splits.forEach(s => s.point && this.deriveElevationData(s.point));
        }

        // Process it to find details like the m dimension, elevation change, etc.
        let properties = processLinestring(linestringFeature);
        Object.assign(linestringFeature.properties, properties);      
        
        // Process waypoints to determine their distance into the race.
        processWaypoints(gj, linestringFeature);
        
        // Create splits for this race based on the GeoJSON
        // These should merge with existing splits (updating the "dumb" splits)
        // if their distance is close enough.
        mergeSplitsWithGeoJSON(this.splits, gj, (split, waypoint) => {
          if (split) {
            // the given waypoint matches the given split
            this.$set(split, 'point', waypoint);
            split.distance = waypoint.geometry?.coordinates[3];
          }
          else {
            split = {
              id: this.$store.getters.randomID(),
              raceId: this.race.id,
              name: waypoint.properties?.name || waypoint.properties?.title || 'New Split',
              distance: Math.round(waypoint.geometry?.coordinates[3]),
              point: waypoint,
            };
        
            let payload = {splits: {}};
            payload.splits[split.id] = split;
            this.$store.commit('commitEntities', payload);
          }
        });
        
        // Create waypoints for all splits we had that weren't matched
        this.splits.forEach(s => {
          if (!s.point) {
            let coords = coordsForDistance(linestringFeature, s.distance);
            s.point = {
              type: 'Feature',
              properties: {
                name: s.name || `Point ${s.distance}`
              },
              geometry: {
                type: 'Point',
                coordinates: coords
              }
            }
          }
        });
        
        this.setGeoJSON(gj); // this time with everything processed.
      },
      
      //@TODO: move to GPXMap2
      deriveElevationData(feature) {
        console.log("Deriving elevation data: ", feature?.properties?.name);

        switch (feature.geometry?.type) {
          case 'LineString': return this.deriveElevationForLineString(feature);
          case 'Point': return this.deriveElevationForPoint(feature);
          default: {
            const type = feature.geometry?.type || '(no geometry)';            
            alert(`Unable to determine GPX information for ${type}. Some features may be unavailable.`);
            debugger
          }
        }
      },

      //@TODO: move to GPXMap2
      // The elevation data must have already been loaded for the query to succeed.
      // 
      deriveElevationForLineString(feature) {
        const map = this.$refs.map.map;
        const exag = map.getTerrain().exaggeration;
        const coords = feature.geometry.coordinates;
        let tuple;
        for (let i = 0; i < coords.length; i++) {
          tuple = coords[i];
          tuple[2] = map.queryTerrainElevation(tuple.slice(0,2)) / exag;
        }
      },
      
      deriveElevationForPoint(feature) {
        const map = this.$refs.map.map;
        const exag = map.getTerrain().exaggeration;
        let coords = feature.geometry.coordinates;

        if (!coords[2]) 
          coords[2] = map.queryTerrainElevation(coords.slice(0,2)) / exag;
        
        const n = feature.properties?.name || 'unknown point';
        console.log(`${n} elevation: ${coords[2]}`);
      },
      
      setGeoJSON(gj, loading=false){
        this.$set(this.race, 'linestring',  gj);
        if (!loading) {
          this.gpxMessage = 'Set.'
          this.gpxUploading = false;
        }
      }
      
    }
  }
</script>